// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
$btnWidth:100px;
$inputWidth:150px;

table{
	.col-btn{
		min-width: $btnWidth;
		max-width: $btnWidth;
		width: $btnWidth;
	}
	.col-input{
		min-width: $inputWidth;
		max-width: $inputWidth;
		width: $inputWidth;
	}
}
